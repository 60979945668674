import React from 'react'
import Seo from '../../component/Seo'
import Layout from '../../component/Layout/Layout'
import { Link } from 'gatsby'
import bg from '../../images/about/bg.svg'
import './_about-us.scss'

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="About Mortgage"/>
      <section className="about-us">
        <div className="about-us__inner">
          <div className="about-us__left">
            <h1 className="about-us__headline">
              About Project
            </h1>
            <p className="about-us__text">
              The demo website has been built to show our capabilities in the mortgage industry. The majority of pages and calculators have been excluded from this demo website, but you can check the
              landing page and the mortgage calculator, developed to demonstrate the speed and performance.
            </p>
            <p className="about-us__text">
              In the process of prototyping, design, and development, we used the best and proven practices to make sure the product is usable, modern, and blazing fast.
            </p>
            <p className="about-us__text">
              In addition to the mortgage calculator that you can check on this website, we also have expertise in building a refinance calculator, a debt consolidation calculator, an affordability
              calculator, a biweekly payment calculator, a retirement calculator, a loan limit tool.
            </p>
            <Link
              className="about-us__link"
              to="/"
            >
              To Home Page
            </Link>
          </div>
          <div className="about-us__right">
            <img src={bg} alt="bg develop" className="about-us__right__image"/>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage